import {
  Component,
  ElementRef,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { KeycloakService } from 'keycloak-angular';
import { AppService } from 'src/app/core';
import { RouteList } from 'src/app/core/common.model';
import { AccessManegerService } from 'src/app/views/access-manager/access-manager.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-side-navbar',
  templateUrl: './side-navbar.component.html',
  styleUrls: ['./side-navbar.component.scss']
})
export class SideNavbarComponent implements OnInit {
  @ViewChildren('panel', { read: ElementRef }) panels!: QueryList<
    ElementRef<HTMLElement>
  >;
  @ViewChild('sidebarMiddle', { static: false })
  sidebarMiddle!: ElementRef<HTMLElement>;

  categoryMap = new Map<string, boolean>([
    ['Tiers', environment.enableTiers],
    ['Subscriptions', environment.enableSubscriptions],
    ['Store Settings', environment.enableStoreSettings]
  ]);

  categories: RouteList[];
  topCategories: RouteList[];
  bottomCategories: RouteList[];
  isShow = true;
  panelOpenState = false;
  isEmbeddedView = false;

  constructor(
    private keycloakService: KeycloakService,
    private appService: AppService,
    private router: Router,
    private accessManagerService: AccessManegerService
  ) {
    this.categories = this.appService.getSideBarNav();
    this.categories.forEach((cat) => {
      cat.canShow = true;
      if (this.categoryMap.has(cat.name)) {
        cat.canShow = this.categoryMap.get(cat.name);
      }
    });
    this.topCategories = this.categories.filter((c) => !c.stickToBottom);
    this.bottomCategories = this.categories.filter((c) => c.stickToBottom);
    this.accessManagerService.getProfileDetail().subscribe((response) => {
      if (response.areAllMerchantsAllowed) {
        return;
      }
      this.topCategories = this.topCategories.filter(
        (cat) => cat.name != 'Subscriptions'
      );
    });
  }

  ngOnInit(): void {
    this.appService.isEmbeddedViewasObservable.subscribe((bool) => {
      this.isEmbeddedView = bool;
    });
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        const currentRoute = this.router.url.split('/')[1];

        let currentActive = this.categories.find((f) =>
          f.link.startsWith(String(currentRoute))
        );
        if (!currentActive) {
          currentActive = this.categories.find((f) => {
            if (f.subMenu) {
              return f.subMenu.some((r) =>
                r.link.startsWith(String(currentRoute))
              );
            }
            return false;
          });
        }
        if (currentActive) currentActive.isExpand = true;
      }
    });
  }

  closeAllExpansionPanels() {
    this.categories.forEach((f) => {
      if (f.subMenu) {
        f.isExpand = false;
      }
    });
  }

  handleAccordion(routeObject: RouteList) {
    routeObject.isExpand = true;
  }

  logout() {
    this.keycloakService.logout(window.location.origin).catch((error) => {
      console.error(error);
    });
  }

  toggleMe() {
    this.isShow = !this.isShow;
  }

  goToHome() {
    this.router.navigate(['/']).catch((error) => console.log(error));
  }

  onAccordionExpanded(c: RouteList) {
    if (!c.stickToBottom) {
      this.scrollExpandedPanelIntoView(c);
    }
  }

  scrollExpandedPanelIntoView(c: RouteList) {
    const expandedPanel = this.panels
      .toArray()
      .find(
        (panel) =>
          panel?.nativeElement
            ?.querySelector('mat-expansion-panel-header')
            ?.textContent?.trim() === c.name
      );
    const panelElement = expandedPanel?.nativeElement;

    if (!this.isElementInViewport(panelElement)) {
      panelElement &&
        panelElement.scrollIntoView({
          behavior: 'smooth',
          block: 'start'
        });
    }
  }

  isElementInViewport(el: HTMLElement | undefined): boolean | undefined {
    const sidebarElement = this.sidebarMiddle.nativeElement;
    const sidebarRect = sidebarElement.getBoundingClientRect();

    const rect = el && el.getBoundingClientRect();

    return (
      rect && rect.top >= sidebarRect.top && rect.bottom <= sidebarRect.bottom
    );
  }
}
