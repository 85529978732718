import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { KeycloakService } from 'keycloak-angular';
import { AppService } from './core/services/app.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'jiox-admin';
  showContent = false;
  isPreview = false;
  showSideNavContent = true;
  isEmbeddedView = false;

  constructor(
    private router: Router,
    private keycloakService: KeycloakService,
    private appService: AppService,
    private route: ActivatedRoute
  ) {
    this.appService.isPreviewPage().subscribe((d) => {
      if (d !== undefined && d !== null) {
        this.isPreview = d;
      }
    });
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      const isEmbedded = String(params['isEmbedded'] || '');
      if (isEmbedded) {
        this.appService.setIsEmbeddedView(true);
        this.isEmbeddedView = true;
      } else {
        this.appService.setIsEmbeddedView(false);
        this.isEmbeddedView = false;
      }

      this.keycloakService
        .isLoggedIn()
        .then((isAuthenticated) => {
          if (isAuthenticated) {
            this.appService.checkRoles();
            this.showContent = true;
          } else if (isEmbedded) {
            this.showContent = true;
          }
          return;
        })
        .catch((err) => console.error(err));
    });
  }

  redirect() {
    this.router.navigate(['home']).catch((e) => console.error(e));
  }

  getSidebarWidth(isEmit: boolean) {
    if (isEmit) {
      this.showSideNavContent = !this.showSideNavContent;
    }
  }
}
