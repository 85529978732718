// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { IEnvironment } from './environment-interface';

export const environment: IEnvironment = {
  production: false,
  //Use 'http://localhost:3000/api' for pointing to local backend
  apiHost: 'https://stg-jio-loyalty.api.engageapps.jio/api',
  newCoAPI: 'https://newco-dev.api.engageapps.jio/api',
  keycloakConfig: {
    //Use 'master' for pointing to local backend
    realm: 'stage',
    url: 'https://dev-keycloak.jio-loyalty.engageapps.jio/auth',
    clientId: 'admin-frontend'
  },
  roles: ['jiox-admin'],
  imgixUrl: 'https://jio-x-dev.imgix.net/',
  customImagesUrl: 'https://stg-jio-loyalty.api.engageapps.jio',
  maxFileSizeMB: {
    bulkUpload: 1
  },
  canShowRomaCSV: true,
  enableTiers: true,
  enableSubscriptions: true,
  canBulkUploadCreateUsers: true,
  enableAnalyticsDashboard: true,
  enableStoreSettings: true,
  analyticsConfig: {
    url: 'https://analytics-ext.api.engageapps.jio',
    dashboardId: '08318ea4-0e93-4561-bf0a-1fc0644bcca1'
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
