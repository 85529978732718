import { HttpContextToken } from '@angular/common/http';
import { RouteList } from '../core/common.model';
import {
  DayFilter,
  Filter
} from '../shared/layout/search-filter/search-filter.class';

export enum InputTypes {
  INPUT = 'input',
  SELECT = 'select',
  DATE = 'date',
  NUMBER = 'number',
  TIME = 'time'
}

export enum Roles {
  JIOX_ADMIN = 'jiox-admin',
  DASHBOARD_READ = 'dashboard.read',
  MERCHANT_FILTER_LIST = 'merchants.list',
  MERCHANT_CREATE = 'merchant.create',
  MERCHANT_LIST = 'merchant.read',
  MERCHANT_PROFILE_READ = 'merchant_profile.read',
  MERCHANT_PROFILE_UPDATE = 'merchant_profile.update',
  MERCHANT_SETTINGS_READ = 'merchant_settings.read',
  MERCHANT_SETTINGS_UPDATE = 'merchant_settings.update',
  MERCHANT_SECRET_READ = 'merchant_secrets.read',
  USER_READ = 'user.read',
  USER_PROFILE_UPDATE = 'user_profile.update',
  USER_NUMBER_UPDATE = 'user_number.update',
  USER_STATUS_UPDATE = 'user_status.update',
  TRANSACTIONS_READ = 'transactions.read',
  POINTS_MANAGEMENT_CREATE = 'pointsmanagement.create',
  ACTIVITY_MANAGER_CREATE = 'activitymanager.create',
  ACTIVITY_MANAGER_UPDATE = 'activitymanager.update',
  ACTIVITY_MANAGER_READ = 'activitymanager.read',
  DERIVED_FIELDS_CREATE = 'derivedfields.create',
  DERIVED_FIELDS_UPDATE = 'derivedfields.update',
  DERIVED_FIELDS_READ = 'derivedfields.read',
  EARNRULES_CREATE = 'earnrules.create',
  EARNRULES_UPDATE = 'earnrules.update',
  EARNRULES_READ = 'earnrules.read',
  COHORTS_CREATE = 'cohorts.create',
  COHORTS_UPDATE = 'cohorts.update',
  COHORTS_READ = 'cohorts.read',
  WEBHOOK_CREATE = 'webhook.create',
  WEBHOOK_UPDATE = 'webhook.update',
  WEBHOOK_READ = 'webhook.read',
  PROGRAM_CUSTOMIZATION_UPDATE = 'programcustomization.update',
  PROGRAM_CUSTOMIZATION_READ = 'programcustomization.read',
  ROLE_MANAGER_CREATE = 'rolemanager.create',
  ROLE_MANAGER_READ = 'rolemanager.read',
  ROLE_MANAGER_UPDATE = 'rolemanager.update',
  ACCESS_MANAGER_CREATE = 'accessmanager.create',
  ACCESS_MANAGER_UPDATE = 'accessmanager.update',
  ACCESS_MANAGER_READ = 'accessmanager.read',
  AUDITLOGS_LIST = 'auditlogs.list',
  TIERS_CREATE = 'tiers.create',
  TIERS_UPDATE = 'tiers.update',
  TIERS_READ = 'tiers.read',
  BULK_UPLOADS_READ = 'bulkuploads.read',
  BULK_UPLOADS_CREATE = 'bulkuploads.create',
  BULK_UPLOADS_UPDATE = 'bulkuploads.update',
  SUBSCRIPTIONS_CREATE = 'subscription.create',
  SUBSCRIPTIONS_UPDATE = 'subscription.update',
  SUBSCRIPTIONS_READ = 'subscription.read',
  ANALYTICS_READ = 'analytics.read',
  APPROVAL_SETUP_CREATE = 'approvalsetup.create',
  APPROVAL_SETUP_UPDATE = 'approvalsetup.update',
  APPROVAL_SETUP_READ = 'approvalsetup.read',
  CURRENCY_CONFIGURATION_CREATE = 'currencyconfiguration.create',
  CURRENCY_CONFIGURATION_UPDATE = 'currencyconfiguration.update',
  CURRENCY_CONFIGURATION_READ = 'currencyconfiguration.read',
  STORE_SETTINGS_UPDATE = 'storeidconfig.update',
  STORE_SETTINGS_READ = 'storeidconfig.read',
  STORE_SETTINGS_CREATE = 'storeidconfig.create'
}

export enum RuleTypes {
  BASE_RULE = 'baseRule',
  BONUS_RULE = 'bonusRule',
  POINTS_MULTIPLIER_RULE = 'pointsMultiplierRule'
}

export const dayFilter: DayFilter[] = [
  { value: { count: 1, type: 'day' }, viewValue: 'Last Day' },
  { value: { count: 15, type: 'day' }, viewValue: 'Last 15 Days' },
  { value: { count: 30, type: 'day' }, viewValue: 'Last 30 Days' },
  { value: { count: 3, type: 'month' }, viewValue: 'Last Quarter' },
  { value: { count: 1, type: 'year' }, viewValue: 'Last Year' }
];

export const APP_CONSTANTS = {
  DEFAULT_PAGE_SIZE: 10
};

export const API_ENDPOINTS = {
  /* App Config */
  appConfig: '/admin/app-config'
};

export const scaleOfIndustry: Filter[] = [
  {
    name: 'Scale Of Industry',
    values: [
      {
        value: { filterType: 'scale', filterValue: 'small' },
        viewValue: 'Small'
      },
      {
        value: { filterType: 'scale', filterValue: 'medium' },
        viewValue: 'Medium'
      },
      {
        value: { filterType: 'scale', filterValue: 'large' },
        viewValue: 'Large'
      }
    ]
  }
];

export const routes: RouteList[] = [
  {
    name: 'Home',
    link: 'dashboard',
    icon: 'home',
    iconActive: 'home',
    roles: [Roles.JIOX_ADMIN, Roles.DASHBOARD_READ],
    shouldHaveAllRoles: false
  },
  {
    name: 'Program',
    link: 'program',
    icon: 'program',
    iconActive: 'program',
    roles: [
      Roles.JIOX_ADMIN,
      Roles.CURRENCY_CONFIGURATION_CREATE,
      Roles.CURRENCY_CONFIGURATION_READ,
      Roles.CURRENCY_CONFIGURATION_UPDATE
    ],
    shouldHaveAllRoles: false
  },
  {
    name: 'Analytics',
    link: 'analytics',
    icon: 'home',
    iconActive: 'home',
    roles: [Roles.ANALYTICS_READ],
    shouldHaveAllRoles: false
  },
  {
    name: 'Partners',
    link: 'partner',
    icon: 'merchantList',
    iconActive: 'merchantList',
    roles: [Roles.JIOX_ADMIN, Roles.MERCHANT_LIST],
    shouldHaveAllRoles: false
  },
  {
    name: 'Members',
    link: 'members',
    icon: 'member',
    iconActive: 'member',
    roles: [Roles.JIOX_ADMIN, Roles.USER_READ],
    shouldHaveAllRoles: false
  },
  {
    name: 'Audit Logs',
    link: 'admin-logs',
    icon: 'transaction',
    iconActive: 'transaction',
    roles: [Roles.JIOX_ADMIN, Roles.AUDITLOGS_LIST],
    shouldHaveAllRoles: true
  },
  {
    name: 'Activity Manager',
    link: 'activity-configuration',
    icon: 'offers',
    iconActive: 'offers',
    roles: [
      Roles.JIOX_ADMIN,
      Roles.ACTIVITY_MANAGER_READ,
      Roles.DERIVED_FIELDS_READ
    ],
    shouldHaveAllRoles: false,
    subMenu: [
      {
        name: 'Activities',
        link: 'activity-configuration/activity-codes',
        icon: 'access-management',
        iconActive: 'access-management',
        roles: [Roles.JIOX_ADMIN, Roles.ACTIVITY_MANAGER_READ],
        shouldHaveAllRoles: false
      },
      {
        name: 'Activity Fields',
        link: 'activity-configuration/additional/activity',
        icon: 'access-management',
        iconActive: 'access-management',
        roles: [Roles.JIOX_ADMIN, Roles.ACTIVITY_MANAGER_READ],
        shouldHaveAllRoles: false
      },
      {
        name: 'Item Fields',
        link: 'activity-configuration/additional/item',
        icon: 'access-management',
        iconActive: 'access-management',
        roles: [Roles.JIOX_ADMIN, Roles.ACTIVITY_MANAGER_READ],
        shouldHaveAllRoles: false
      },
      {
        name: 'Derived Fields',
        link: 'activity-configuration/derived-fields',
        icon: 'access-management',
        iconActive: 'access-management',
        roles: [Roles.JIOX_ADMIN, Roles.DERIVED_FIELDS_READ],
        shouldHaveAllRoles: false
      }
    ]
  },
  //Disabled since we don't want this to be live yet.
  // {
  //   name: 'Add Star',
  //   link: 'star',
  //   icon: 'addStar',
  //   iconActive: 'addStar',
  //   roles: ['jiox-admin', 'addstars.list']
  // },
  {
    name: 'Earn Rules',
    link: 'rules',
    icon: 'earnRules',
    iconActive: 'earnRules',
    roles: [Roles.JIOX_ADMIN, Roles.EARNRULES_READ],
    shouldHaveAllRoles: false
  },
  //Disabled since we don't want this to be live yet.
  // {
  //   name: 'Offer Management',
  //   link: 'offer',
  //   icon: 'offers',
  //   iconActive: 'offers',
  //   roles: ['jiox-admin'],
  //   subMenu: [
  //     {
  //       name: 'Offer Dashboard',
  //       link: 'offer',
  //       icon: 'access-management',
  //       iconActive: 'access-management',
  //       roles: ['jiox-admin']
  //     },
  //     {
  //       name: 'Create Offer',
  //       link: 'offer/create',
  //       icon: 'access-management',
  //       iconActive: 'access-management',
  //       roles: ['jiox-admin']
  //     },
  //     {
  //       name: 'Offer Rule',
  //       link: 'offer/123',
  //       icon: 'access-management',
  //       iconActive: 'access-management',
  //       roles: ['jiox-admin']
  //     }
  //   ]
  // },
  {
    name: 'Cohorts',
    link: 'cohorts',
    icon: 'merchantList',
    iconActive: 'merchantList',
    roles: [Roles.JIOX_ADMIN, Roles.COHORTS_READ],
    shouldHaveAllRoles: false
  },
  {
    name: 'Tiers',
    link: 'tiers',
    icon: 'tier',
    iconActive: 'tier',
    roles: [Roles.JIOX_ADMIN, Roles.TIERS_READ],
    shouldHaveAllRoles: false
  },
  {
    name: 'Bulk Points Upload',
    link: 'bulk-points',
    icon: 'bulk-points',
    iconActive: 'bulk-points',
    roles: [Roles.JIOX_ADMIN, Roles.BULK_UPLOADS_READ],
    shouldHaveAllRoles: false
  },
  {
    name: 'Webhooks',
    link: 'webhook',
    icon: 'webhook',
    iconActive: 'webhook',
    roles: [Roles.JIOX_ADMIN, Roles.WEBHOOK_READ],
    shouldHaveAllRoles: false
  },
  {
    name: 'Customize',
    link: 'customize',
    icon: 'custom',
    iconActive: 'custom',
    roles: [Roles.JIOX_ADMIN, Roles.PROGRAM_CUSTOMIZATION_READ],
    shouldHaveAllRoles: false
  },
  {
    name: 'Subscriptions',
    link: 'subscriptions',
    icon: 'subscription',
    iconActive: 'subscription',
    roles: [Roles.JIOX_ADMIN, Roles.SUBSCRIPTIONS_READ],
    shouldHaveAllRoles: false
  },
  {
    name: 'Approvals',
    link: 'approvals',
    icon: 'approvals',
    iconActive: 'approvals',
    roles: [Roles.JIOX_ADMIN, Roles.APPROVAL_SETUP_READ],
    shouldHaveAllRoles: false,
    subMenu: [
      {
        name: 'Approvals Setup',
        link: 'approvals/approval-setup',
        icon: 'access-management',
        iconActive: 'access-management',
        roles: [
          Roles.JIOX_ADMIN,
          Roles.APPROVAL_SETUP_CREATE,
          Roles.APPROVAL_SETUP_READ,
          Roles.APPROVAL_SETUP_UPDATE
        ],
        shouldHaveAllRoles: false
      },
      {
        name: 'Manage Approvals',
        link: 'approvals/manage-approvals',
        icon: 'access-management',
        iconActive: 'access-management',
        roles: [
          Roles.JIOX_ADMIN,
          Roles.APPROVAL_SETUP_CREATE,
          Roles.APPROVAL_SETUP_READ,
          Roles.APPROVAL_SETUP_UPDATE
        ],
        shouldHaveAllRoles: false
      }
    ]
  },
  {
    name: 'Security Settings',
    link: 'portal-settings',
    icon: 'access-management',
    iconActive: 'access-management',
    roles: [
      Roles.JIOX_ADMIN,
      Roles.ROLE_MANAGER_READ,
      Roles.ACCESS_MANAGER_READ
    ],
    shouldHaveAllRoles: false,
    stickToBottom: true,
    subMenu: [
      {
        name: 'Role Manager',
        link: 'roles',
        icon: 'access-management',
        iconActive: 'access-management',
        roles: [
          Roles.JIOX_ADMIN,
          Roles.ROLE_MANAGER_CREATE,
          Roles.ROLE_MANAGER_READ,
          Roles.ROLE_MANAGER_UPDATE
        ],
        shouldHaveAllRoles: false
      },
      {
        name: 'Access Manager',
        link: 'access-management',
        icon: 'access-management',
        iconActive: 'access-management',
        roles: [Roles.JIOX_ADMIN, Roles.ACCESS_MANAGER_READ],
        shouldHaveAllRoles: false
      }
    ]
  },
  {
    name: 'Store Settings',
    link: 'store-settings',
    icon: 'store',
    iconActive: 'store',
    roles: [
      Roles.JIOX_ADMIN,
      Roles.STORE_SETTINGS_CREATE,
      Roles.STORE_SETTINGS_READ,
      Roles.STORE_SETTINGS_UPDATE
    ],
    shouldHaveAllRoles: false
  }
];

export const SHOW_LOADER = new HttpContextToken<boolean>(() => true);
