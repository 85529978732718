import {
  Directive,
  ElementRef,
  HostListener,
  Input,
  OnInit
} from '@angular/core';
import { AppService } from 'src/app/core';
import { MatTooltip } from '@angular/material/tooltip';

@Directive({
  selector: '[appIsDisabled]',
  providers: [MatTooltip]
})
export class IsDisabledDirective implements OnInit {
  @Input() role!: string[] | string;
  tooltipText = 'You dont have access to this activity';

  constructor(
    private el: ElementRef<HTMLElement>,
    private appService: AppService,
    private tooltip: MatTooltip
  ) {}

  @HostListener('mouseover') mouseover() {
    if (
      !this.appService.doesUserHaveRole(
        Array.isArray(this.role) ? this.role : [this.role]
      )
    ) {
      this.tooltip.message = this.tooltipText;
      this.tooltip.show();
    }
  }

  @HostListener('mouseleave') mouseleave() {
    this.tooltip.hide();
  }

  ngOnInit() {
    if (
      !this.appService.doesUserHaveRole(
        Array.isArray(this.role) ? this.role : [this.role]
      )
    ) {
      this.el.nativeElement.style.display = 'none';
      this.el.nativeElement.classList.add('disable');
    }
  }
}
