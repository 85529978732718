<div class="table table-scroll" #tableReference>
  <table matSort mat-table matSortDisableClear [dataSource]="dataSource" (matSortChange)="sortChange($event)"
    [matSortActive]="_config.sortOn" [matSortDirection]="_config.sortOrder" [matSortDisabled]="!_config.sorting">
    <ng-container *ngFor="let column of columns">
      <ng-container [matColumnDef]="column['key']" [sticky]="column.isSticky">
        <th mat-header-cell *matHeaderCellDef mat-sort-header [disabled]="!column.isSort" appTooltipEllipses>
          {{ column['label'] }}               
          <div class="info-icon" *ngIf="column.tooltip" [matTooltipClass]="'customtooltip'" [matTooltipPosition]="'right'"
          matTooltip={{column.tooltip}}>
          <img src="assets/images/roles/roleicon.svg">
        </div>
        </th>
        <ng-container *ngIf="!column.template">
          <ng-container *ngIf="!column.type">
            <td mat-cell *matCellDef="let element" class="{{column.tdExtraClass || ''}}" appTooltipEllipses>
              {{ element[column['key']] }}
            </td>
          </ng-container> 
          <!-- For Type -->
          <ng-container *ngIf="column.type">
            <ng-container *ngIf="column.type === 'date'">
              <td mat-cell *matCellDef="let element" appTooltipEllipses>
                {{ element[column['key']] | date: (column.format || 'mediumDate') }}
              </td>
            </ng-container>
            <ng-container *ngIf="column.type === 'status'">
              <td mat-cell *matCellDef="let element" appTooltipEllipses>
                {{ element[column['key']] ? 'Active' : 'Inactive' }}
              </td>
            </ng-container>
          </ng-container>
        </ng-container>
        <!-- For Template -->
        <ng-container *ngIf="column.template">
          <td mat-cell *matCellDef="let element" class="{{column.tdExtraClass || ''}}" appTooltipEllipses>
            <ng-container *ngIf="column.template" [ngTemplateOutlet]="column.template"
              [ngTemplateOutletContext]="{ data: element, field: column.key, column: column }">
            </ng-container>
          </td>
        </ng-container>
      </ng-container>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="columnKeys; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: columnKeys"></tr>
  </table>
  <ng-container *ngIf="(!dataSource.data || dataSource.data.length === 0 || !dataSource) && !isLoader">
    <div class="no-data">{{results}}</div>
  </ng-container>
</div>

<ng-container *ngIf="paginationData">
  <div class="pagination">

    <div class="total-items">
      <span class="count">
        10
      </span>
      Items Per Page
    </div>


    <ng-container *ngIf="!paginationData.isTotalRecordsKnown">
      <div>
        <button mat-icon-button [disabled]="paginationData.isFirstPage" class="text-violet"
          (click)="onPageChange(pageArrows.LEFT)">
          <mat-icon [ngClass]="{
            'text-violet': !paginationData.isFirstPage,
            active: paginationData.isFirstPage
          }">keyboard_arrow_left</mat-icon>
        </button>
        <button class="text-violet" mat-icon-button [disabled]="paginationData.isLastPage"
          (click)="onPageChange(pageArrows.RIGHT)">
          <mat-icon [ngClass]="{
            'text-violet': !paginationData.isLastPage,
            active: paginationData.isLastPage
          }">keyboard_arrow_right</mat-icon>
        </button>
      </div>
    </ng-container>


    <div class="right">
      <ng-container *ngIf="paginationData.totalCount">
        <div class="total-items">
          Total Items:
          <span class="count">
            {{paginationData.totalCount | number}}
          </span>
        </div>
      </ng-container>
    </div>
  </div>
</ng-container>